import { createPopper } from "@popperjs/core";

const jQuery = window.jQuery;

export default {
  init() {
    // JavaScript to be fired on all pages
    // Search in Header
    const searchWidget = jQuery("#product-search-widget");
    const searchButton = jQuery("#product-search-button");

    searchWidget.css("display", "none");
    searchButton.click((event) => {
      event.preventDefault();
      searchWidget.toggle(400, () => {
        if (searchWidget.css("display") === "block") {
          searchWidget.find("input").focus();
        }
      });
    });

    // Header Dropdowns
    const showEvents = ["mouseenter", "focus"];
    const hideEvents = ["mouseleave", "blur"];

    const headerCartButton = jQuery("#header-cart-button");
    const headerCartWidget = jQuery("#header-cart-widget");
    const headerWishlistButton = jQuery("#header-wishlist-button");
    const headerWishlistWidget = jQuery("#header-wishlist-widget");

    const cartPopper = createPopper(
      headerCartButton.get(0),
      headerCartWidget.get(0),
      {
        modifiers: [{ name: "offset", options: { offset: [0, 5] } }],
      }
    );
    const wishPopper = createPopper(
      headerWishlistButton.get(0),
      headerWishlistWidget.get(0),
      {
        modifiers: [{ name: "offset", options: { offset: [0, 5] } }],
      }
    );

    let wishlistTimeout;
    let cartTimeout;

    function showCart() {
      clearTimeout(cartTimeout);
      headerCartWidget.attr("data-show", "");
      cartPopper.forceUpdate();
    }

    function hideCart() {
      cartTimeout = setTimeout(() => {
        headerCartWidget.removeAttr("data-show");
      }, 500);
    }

    function showWishlist() {
      clearTimeout(wishlistTimeout);
      headerWishlistWidget.attr("data-show", "");
      wishPopper.forceUpdate();
    }

    function hideWishlist() {
      wishlistTimeout = setTimeout(() => {
        headerWishlistWidget.removeAttr("data-show");
      }, 500);
    }

    showEvents.forEach((event) => {
      headerCartButton.on(event, () => showCart());
      headerCartWidget.on(event, () => showCart());
      headerWishlistButton.on(event, () => showWishlist());
      headerWishlistWidget.on(event, () => showWishlist());
    });

    hideEvents.forEach((event) => {
      headerCartButton.on(event, () => hideCart());
      headerCartWidget.on(event, () => hideCart());
      headerWishlistButton.on(event, () => hideWishlist());
      headerWishlistWidget.on(event, () => hideWishlist());
    });

    // Equal Height Products
    jQuery(".products").each(function () {
      let tallest = 0;
      jQuery(this)
        .find(".product:not(.product-category)")
        .each(function () {
          if (jQuery(this).height() > tallest) {
            tallest = jQuery(this).height() + 40;
          }
        });
      jQuery(this).find(".product:not(.product-category)").height(tallest);
    });

    // Character count in product options
    jQuery(".tmcp-textarea").each(function () {
      const textareaClasses = jQuery(this).attr("class").split(" ");
      textareaClasses.forEach((klass) => {
        const hasCount = klass.includes("spi_character");
        if (!hasCount) {
          return;
        }

        const hasCountSplit = klass.split("_");
        const maxLength = hasCountSplit[hasCountSplit.length - 1];
        const maxRows = jQuery(this).attr("maxLength") / maxLength;

        jQuery(this).on("input focus keydown keyup", function () {
          const text = jQuery(this).val();
          const lines = text.split("\n");

          for (let i = 0; i < lines.length; i++) {
            const trimmed = lines[i].trim();
            if (trimmed.length > maxLength) {
              lines[i] = trimmed.substring(0, maxLength) + "\r";
            }
          }

          while (lines.length > maxRows) {
            lines.pop();
          }

          jQuery(this).val(lines.join("\n"));
        });
      });
    });
  },

  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
